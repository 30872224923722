<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab
        v-for="item in items"
        :key="item.value"
        @click="changeTab(item.value)"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.value">
        <div class="my-2"></div>
        <div v-for="msgItem in msgList" :key="msgItem.id">
          <v-hover>
            <template #default="{ hover }">
              <v-card
                :elevation="hover ? 6 : 0"
                class="py-2 px-5"
                style="cursor: pointer"
                @click="clickMsg(msgItem)"
              >
                <div class="d-flex align-center">
                  <div class="left_icon">
                    <v-icon
                      :color="
                        msgItem.status == '01'
                          ? 'blue darken-2'
                          : 'grey darken-2'
                      "
                      >mdi-message-text
                    </v-icon>
                  </div>
                  <div>
                    <div
                      :class="
                        msgItem.status == '01'
                          ? 'text-subtitle-1 ml-1 black--text'
                          : 'text-subtitle-1 ml-1 grey--text t'
                      "
                    >
                      {{ msgItem.content }}
                    </div>
                    <div
                      :class="
                        msgItem.status == '01'
                          ? 'text-body-2 black--text'
                          : 'text-body-2 grey--text'
                      "
                    >
                      {{ msgItem.createdTime }}
                    </div>
                  </div>
                </div>
              </v-card>
            </template>
          </v-hover>
          <div class="my-1"></div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tab: null,
      items: [
        { name: '全部消息', value: ' ' },
        { name: '未读消息', value: 'N' },
      ],
      msgList: [],
    };
  },
  created() {
    this.getMsgLogList(' ');
  },
  methods: {
    changeTab(tab) {
      this.getMsgLogList(tab);
    },
    getMsgLogList(read) {
      let param = {
        read: read,
      };
      this.$api.mine.mine.getmessger(param).then((res) => {
        this.msgList = res.data;
      });
    },
    clickMsg(item) {
      let param = {
        uuid: item.uuid,
      };
      this.$api.system.msgLog.updateMsgReadStatus(param).finally(() => {
        for (let i = 0; i < this.msgList.length; i++) {
          if (this.msgList[i].id == item.id) {
            this.msgList[i].readStatus = '1';
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.left_icon {
  margin-right: 10px;
}
.text-body-2 {
  font-size: 10px !important;
}
.text-subtitle-1 {
  font-size: 12px !important;
}
</style>
