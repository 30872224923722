var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.value,on:{"click":function($event){return _vm.changeTab(item.value)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.value},[_c('div',{staticClass:"my-2"}),_vm._l((_vm.msgList),function(msgItem){return _c('div',{key:msgItem.id},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"py-2 px-5",staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 6 : 0},on:{"click":function($event){return _vm.clickMsg(msgItem)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"left_icon"},[_c('v-icon',{attrs:{"color":msgItem.status == '01'
                        ? 'blue darken-2'
                        : 'grey darken-2'}},[_vm._v("mdi-message-text ")])],1),_c('div',[_c('div',{class:msgItem.status == '01'
                        ? 'text-subtitle-1 ml-1 black--text'
                        : 'text-subtitle-1 ml-1 grey--text t'},[_vm._v(" "+_vm._s(msgItem.content)+" ")]),_c('div',{class:msgItem.status == '01'
                        ? 'text-body-2 black--text'
                        : 'text-body-2 grey--text'},[_vm._v(" "+_vm._s(msgItem.createdTime)+" ")])])])])]}}],null,true)}),_c('div',{staticClass:"my-1"})],1)})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }